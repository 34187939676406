import React from "react";
import { LoginResult, UserInfo, userApi } from "../api/user";

export class Account {
  id?: string;
  username?: string;
  constructor(
    user?: UserInfo,
    private setter?: React.Dispatch<React.SetStateAction<UserInfo | undefined>>
  ) {
    if (user) {
      this.id = user.id;
      this.username = user.username;
    }
  }

  get hasLogin() {
    return !!this.id;
  }

  async login(username: string, password: string): Promise<LoginResult> {
    if (!this.setter) {
      throw new Error("setter is not set");
    }
    const result = await userApi.login(username, password);
    if (result.success && result.user) {
      this.setter(result.user);
      this.id = result.user.id;
      this.username = result.user.username;
    }
    return result
  }

  async logout() {
    this.setter?.(undefined);
    this.id = undefined;
    this.username = undefined;
  }

  async deschool() { }
}

export const UserContext = React.createContext<Account>(new Account());

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<UserInfo | undefined>();
  const account = new Account(user, setUser);
  return (
    <UserContext.Provider value={account}>{children}</UserContext.Provider>
  );
};
