"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeSchoolSDK = void 0;
const client_1 = require("@deschool-protocol/client");
const wallet_1 = require("./wallet/wallet");
class DeSchoolSDK extends client_1.DeSchoolClient {
    constructor(config) {
        const dc = {
            ...config,
            endpoint: config.endpoint,
        };
        super(dc);
    }
    async authorize(bizUserId, walletType, chain) {
        const wallet = (0, wallet_1.createWallet)(walletType, chain);
        const addrs = await wallet.requestAddresses();
        if (addrs.length == 0) {
            throw new Error("No addresses returned");
        }
        const addr = addrs[0];
        const auth = this.auth;
        const signMessage = await auth.getLoginSignMessage(addr, walletType);
        const sign = await wallet.signMessage({
            account: addr,
            message: signMessage,
        });
        const result = await auth.verify(addr, sign, walletType);
        this.setAccessToken(result.jwtToken);
        return auth.authorizeApp(bizUserId);
    }
}
exports.DeSchoolSDK = DeSchoolSDK;
