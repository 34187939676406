import {
  Button,
  Card,
  Col,
  Modal,
  Row,
  notification,
  Form,
  Input,
  Select,
  Alert,
} from "antd";
import { useState, useContext } from "react";
import { UserContext } from "../../context/user";
import { sdk } from "../../api/deschool";
import { useDeSchool } from "../../context/deschool";

export type Author = {
  id: string;
  username: string;
  avatar: string;
  address: string;
  bio: string;
};

export type CourseItemType = {
  id: string;
  title: string;
  description: string;
  coverImage: string;
  author: Author;
  pass: CoursePassItemType;
  posk: CoursePoSKItemType;
};

export type CoursePassItemType = {
  id: string;
  type: string;
  title: string;
  description: string;
  passImg: string;
};

export type CoursePoSKItemType = {
  id: string;
  type: string;
  title: string;
  description: string;
  poskImg: string;
};

type AntdNotificationType = "success" | "info" | "warning" | "error";

const Courses = () => {
  // Antd notification relatives
  const [notifyApi] = notification.useNotification();

  const openNotificationWithIcon = (
    type: AntdNotificationType,
    title: string,
    desc: string
  ) => {
    notifyApi[type]({
      message: title,
      description: desc,
    });
  };

  // Get DeSchool Protocol User Context
  const deschool = useDeSchool();
  const appUser = useContext(UserContext);

  const isLogined = () => {
    return deschool.user === undefined;
  };

  // Course
  const [courseList, setCourseList] = useState<CourseItemType[] | undefined>([
    {
      id: "1",
      title: "少儿编程基础",
      description:
        "This course introduces the onboarding process of product guilds.",
      coverImage:
        "https://img.tusij.com/ips_templ_preview/2020-09-22/03/03255e2a-150f-4d75-b4e8-46346dd841d1.jpg?auth_key=2292484235-0-0-e38496c385751f783c37bfcd222ec137&x-oss-process=image/resize,m_fixed,h_951,w_700/crop,x_0,y_0,w_700,h_476",
      author: {
        id: "62f0adc28b90ee1aa913a94e",
        username: "KC",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTyuCwSGCSFDd2fiwEJIivTZMtyi_C-rJviL6eaNYj_D6JSCsqGeNKxGSikjn8QcPqPvWQ&usqp=CAU",
        address: "puppuccino.eth",
        bio: "",
      },
      pass: {},
    },
    {
      id: "63243deab4220dd9474340e2",
      title: "少儿编程进阶",
      description:
        "Take you to understand the basic knowledge of literacy level about DAO",
      coverImage:
        "https://midpf-material.cdn.bcebos.com/65582806feffa16375a3d4535878c91a.jpeg",

      author: {
        id: "6323d96759c18e0e54fd677a",
        username: "shawn",
        avatar:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRWX3RZlNpcAspP46l_5855HVdmSaaigNODySTlswbkwCgveWCucpc-q_Exq8ksRVFwJjk&usqp=CAU",
        address: "0x27B503fd677dd889ea3E7804DF492D2D809E6052",
        bio: "^-^",
      },
      pass: {},
    },
  ] as CourseItemType[]);

  const onCrsOk = async (value: any) => {
    // Bind the course pass to the course, if the course pass is not empty
    if (value.cp) {
      let passId = coursePassList?.filter(
        (passItem: CoursePassItemType) => passItem.id === value.cp
      )[0].id;

      if (passId) {
        try {
          let res = await sdk.pass.bindTargetToPass({
            targetId: value.id,
            targetType: "Course",
            bindings: [
              {
                passId,
                condition: 1,
              },
            ],
          });
          console.log(res);
        } catch (error) {
          console.log(error);
          return;
        }
      }
    }

    // Bind the course posk to the course, if the course posk is not empty
    if (value.posk) {
      let posk = poskList?.filter(
        (poskItem: CoursePoSKItemType) => poskItem.id === value.posk
      )[0];

      if (posk) {
        try {
          let res = await sdk.posk.bindPoskTarget({
            poskId: posk.id,
            targetId: value.id,
            targetType: "Course",
          });
          console.log(res);
        } catch (error) {
          console.log(error);
          return;
        }
      }
    }

    // change the course list
    await setCourseList(
      courseList
        ? courseList
            .filter((courseItem: CourseItemType) => courseItem.id !== value.id)
            .concat({
              id: value.id,
              title: value.title,
              description: value.description,
              coverImage: value.coverImage,
              author: {
                id: "",
                address: "",
                avatar: value.authorAvatar,
                username: value.authorUsername,
                bio: "",
              },
              pass: coursePassList?.filter(
                (passItem: CoursePassItemType) => passItem.id === value.cp
              )[0],
              posk: poskList?.filter(
                (poskItem: CoursePoSKItemType) => poskItem.id === value.posk
              )[0],
            } as CourseItemType)
        : ([
            {
              id: value.id,
              title: value.title,
              author: {
                id: "",
                address: "",
                avatar: value.authorAvatar,
                username: value.authorUsername,
                bio: "",
              },
              description: value.description,
              coverImage: value.coverImage,
            },
          ] as CourseItemType[])
    );
    setIsCrsShown(false);
    console.log(courseList);
  };

  const onCrsCancel = () => {
    setIsCrsShown(false);
  };

  const handleClickAddCourse = () => {
    setIsCrsShown(true);
    crsForm.resetFields();
  };

  const onModifyCourseCard = (id: string) => {
    // filter out the course with id
    let course = courseList?.filter(
      (courseItem: CourseItemType) => courseItem.id === id
    )[0];
    setIsCrsShown(true);

    // set the form value
    crsForm.setFieldsValue({
      id: course?.id,
      title: course?.title,
      description: course?.description,
      coverImage: course?.coverImage,
      authorAvatar: course?.author.avatar,
      authorUsername: course?.author.username,
      cp: course?.pass.title,
    });
  };

  const onDeleteCourseCard = (id: string) => {
    setCourseList(
      courseList?.filter((courseItem: CourseItemType) => courseItem.id !== id)
    );
  };

  // Course Pass
  const [coursePassList, setCoursePassList] = useState<
    CoursePassItemType[] | undefined
  >([
    {
      id: "101",
      type: "ERC 1155",
      title: "少儿编程课程体验卡 - 3节 VIP 课 【非用于绑定】",
      description:
        "注：本条用于内容展示，不可绑定课程，若体验绑定课程卡功能，请先自己创建 Course Pass",
      passImg:
        "https://imgs.qiubiaoqing.com/qiubiaoqing/imgs/606143d66057531G.gif",
    },
  ] as CoursePassItemType[]);

  const handleClickAddCoursePass = () => {
    setIsCPshown(true);
  };

  const onCpOk = async (value: any) => {
    setIsCPshown(false);

    // Create Pass to DeSchool Protocol Server
    try {
      let res = await sdk.pass.createPass({
        adminBizUserId: appUser.id,
        description: value.description,
        image: value.passImg,
        name: value.title,
        studioId: "DeSchool_TEST_STUDIO_ID",
      } as any);

      // PassCreateRequest notification
      openNotificationWithIcon(
        "success",
        "PassCreateRequest Success",
        "The request has been successfully sent to the DeSchool Protocol Server."
      );
      console.log(res);

      // change the course pass list
      let cpId = res.id;
      setCoursePassList(
        coursePassList
          ? coursePassList.concat({
              id: cpId,
              title: value.title,
              type: "ERC 1155",
              description: value.description,
              passImg: value.passImg,
            })
          : [
              {
                id: "101",
                title: value.title,
                type: "ERC 1155",
                description: value.description,
                passImg: value.passImg,
              },
            ]
      );
    } catch (err) {
      console.log(err);
      openNotificationWithIcon(
        "error",
        "PassCreateRequest Failed",
        "The request has been failed to send to the DeSchool Protocol Server."
      );
    }
  };

  const onCpCancel = () => {
    setIsCPshown(false);
  };

  // PoSK
  const [poskList, setPoskList] = useState<CoursePoSKItemType[] | undefined>([
    {
      id: "201",
      type: "ERC 1155",
      title: "少儿编程 PoSK - 3节 VIP 课【非用于绑定】",
      description:
        "注：本条用于内容展示，不可绑定课程，若体验绑定课程卡功能，请先自己创建 PoSK List",
      poskImg:
        "https://img0.baidu.com/it/u=2561435110,3629895186&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500",
    },
  ] as CoursePoSKItemType[]);

  const onViewPosk = async (id: string) => {
    // filter out the posk with id
    let posk = poskList?.filter(
      (poskItem: CoursePoSKItemType) => poskItem.id === id
    )[0];
    setIsPoskShown(true);
    setDisablePoskEdit(true);
    poskForm.setFieldsValue({
      id: posk?.id,
      title: posk?.title,
      description: posk?.description,
      poskImg: posk?.poskImg,
    });
  };

  let cloudPoskId: string = "";
  const onPoskOk = async (value: any) => {
    setIsPoskShown(false);
    if (disablePoskEdit) {
      return;
    }
    try {
      let res = await sdk.posk.createPosk({
        contractId: "",
        name: value.title,
        description: value.description,
        image: value.poskImg,
        studioId: "DeSchool_TEST_STUDIO_ID",
      });
      console.log(res);

      cloudPoskId = res.id;
    } catch (err) {
      console.log(err);
      return;
    }
    setPoskList(
      poskList
        ? poskList.concat({
            id: cloudPoskId,
            title: value.title,
            type: "ERC 1155",
            description: value.description,
            poskImg: value.poskImg,
          })
        : [
            {
              id: "201",
              title: value.title,
              type: "ERC 1155",
              description: value.description,
              poskImg: value.poskImg,
            },
          ]
    );
    setDisablePoskEdit(false);
  };

  const onPoskCancel = () => {
    setIsPoskShown(false);
    setDisablePoskEdit(false);
  };

  const handleClickAddPosk = () => {
    setIsPoskShown(true);
    poskForm.resetFields();
  };

  const handleAirdropPosk = async (id: string) => {
    try {
      let res = await sdk.posk.airdropPosk(
        id,
        appUser.id?.toString() as string
      );
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  // Three forms vars
  const [cpForm] = Form.useForm();
  const [crsForm] = Form.useForm();
  const [poskForm] = Form.useForm();

  // Three vars that control whether to show forms
  const [isCPshown, setIsCPshown] = useState(false);
  const [isCrsShown, setIsCrsShown] = useState(false);
  const [isPoskShown, setIsPoskShown] = useState(false);
  const [disablePoskEdit, setDisablePoskEdit] = useState(false);

  return (
    <div style={{ margin: 20 }}>
      {/* Message for this tech demo */}
      <Alert
        message="欢迎来到 DeSchool Protocol Demo！"
        description={
          <div>
            本 Demo 模拟了一个在线少儿编程教育平台接入 DeSchool Protocol
            的场景，目前协议处于第一阶段， 已支持的功能有：
            <br />
            <br />
            1. Course Pass 的添加（本页）
            <br />
            2. Course Pass 的查看（Profile 页）
            <br />
            3. 链上证书 SBT 的发放、查看、修改。
            <br />
            4. 用户信息查询
            <br />
            5. Connect to DeSchool
            <br />
            <br />
            从右上角 Login 进入，使用以下信息即可成功登陆并体验功能：
            <br />
            <br />
            账户：admin
            <br />
            密码：admin
            <br />
            <br />
            如果在体验 Protocol Demo 的过程中有任何疑问，欢迎加入 DeSchool 官方
            Telegram 群进行交流：https://t.me/deschoolcommunity。
          </div>
        }
        type="info"
        closable
      />

      {/* Two Buttons - Add course & course pass */}
      <Button
        onClick={handleClickAddCourse}
        style={{ margin: "20px 0px" }}
        disabled={isLogined()}
      >
        Add Course
      </Button>
      <Button
        onClick={handleClickAddCoursePass}
        style={{ margin: "20px 0px" }}
        disabled={isLogined()}
      >
        Add Course Pass
      </Button>
      <Button
        onClick={handleClickAddPosk}
        style={{ margin: "20px 0px" }}
        disabled={isLogined()}
      >
        Add PoSK
      </Button>

      {/* Course List Section */}
      <h2 className="m-4"> Course List </h2>
      <Row gutter={16}>
        {courseList?.map((courseItem: CourseItemType) => (
          <Col span={8} key={courseItem.id}>
            <Card style={{ minHeight: 460 }} bordered={false} hoverable>
              <img
                src={courseItem.coverImage}
                alt="course cover"
                style={{ width: 400, height: 260 }}
              />
              <h1 className="font-bold my-2">{courseItem.title}</h1>
              <p style={{ height: 66, overflow: "hidden" }}>
                {courseItem.description}
              </p>
              {/* 作者区 & Pass 信息区 */}
              <div className="flex justify-between items-center">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    style={{
                      width: "20px",
                      height: "20px",
                      borderRadius: "50%",
                      display: "inline",
                    }}
                    src={courseItem.author.avatar}
                    alt=""
                  />
                  <span>{courseItem.author.username}</span>
                </div>
                <div style={{ float: "right" }}>
                  <span style={{ color: "lightGray" }}>
                    {courseItem.pass?.type}
                  </span>
                  <span
                    style={{
                      border: "1px solid",
                      padding: "2px 8px",
                      marginLeft: 6,
                      borderRadius: 4,
                    }}
                  >
                    {courseItem.pass?.title
                      ? courseItem.pass?.title
                      : "暂未绑定 Pass"}
                  </span>
                </div>
              </div>

              {/* 按钮区 */}
              <div className="mt-4">
                <div>以 Admin 身份登陆后，可修改或删除课程：</div>
                <Button
                  onClick={() => onModifyCourseCard(courseItem.id)}
                  disabled={isLogined()}
                >
                  修改课程信息
                </Button>
                <Button
                  onClick={() => onDeleteCourseCard(courseItem.id)}
                  danger
                  disabled={isLogined()}
                >
                  删除课程
                </Button>
                <Button
                  onClick={() => handleAirdropPosk(courseItem.id)}
                  disabled={isLogined()}
                >
                  领取学历
                </Button>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Course Pass Section*/}
      <h2 className="m-4"> Course Pass List </h2>
      <Row gutter={16}>
        {coursePassList?.map((coursePassItem: CoursePassItemType) => (
          <Col span={8} key={coursePassItem.id}>
            <Card bordered={false} hoverable>
              <img
                src={coursePassItem.passImg}
                alt="course pass"
                style={{ width: 400, height: 260 }}
              />
              <h1 className="font-bold my-2">{coursePassItem.title}</h1>
              <p style={{ height: 66, overflow: "hidden" }}>
                {coursePassItem.description}
              </p>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Posk Section*/}
      <h2 className="m-4"> PoSK SBT List </h2>
      <Row gutter={16}>
        {poskList?.map((poskItem: CoursePoSKItemType) => (
          <Col span={8} key={poskItem.id}>
            <Card bordered={false} hoverable>
              <img
                src={poskItem.poskImg}
                alt="course pass"
                style={{ width: 400, height: 260 }}
              />
              <h1 className="font-bold my-2">{poskItem.title}</h1>
              <p style={{ height: 66, overflow: "hidden" }}>
                {poskItem.description}
              </p>
              <div className="mt-4">
                <Button
                  onClick={() => onViewPosk(poskItem.id)}
                  disabled={isLogined()}
                >
                  查看学历 PoSK
                </Button>
              </div>
              <div className="mt-4">
                <div>任一身份登陆后，可模拟完成课程后领取链上学历：</div>
              </div>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Course New or Edit Modal */}
      <Modal open={isCrsShown} onCancel={onCrsCancel} footer={null}>
        <Form form={crsForm} onFinish={onCrsOk}>
          <Form.Item label="Course ID" name="id">
            <Input placeholder="please input course' id" />
          </Form.Item>
          <Form.Item label="Course Title" name="title">
            <Input placeholder="please input course' title" />
          </Form.Item>
          <Form.Item label="Course Description" name="description">
            <Input.TextArea placeholder="please input course' description" />
          </Form.Item>
          <Form.Item label="Course Cover Image URL" name="coverImage">
            <Input placeholder="please input course' cover image url" />
          </Form.Item>
          <Form.Item label="Course Author Avatar URL" name="authorAvatar">
            <Input placeholder="please input course' author avatar url" />
          </Form.Item>
          <Form.Item label="Course Author Username" name="authorUsername">
            <Input placeholder="please input course' author username" />
          </Form.Item>
          <Form.Item label="Course Pass" name="cp">
            <Select
              options={coursePassList
                ?.filter(
                  (coursePassItem: CoursePassItemType) =>
                    // 过滤掉 id 为 101 的 course pass，本 course pass 为内容展示值
                    coursePassItem.id !== "101"
                )
                .map((coursePassItem: CoursePassItemType) => ({
                  label: coursePassItem.title,
                  value: coursePassItem.id,
                }))}
            />
          </Form.Item>
          <Form.Item label="Course PoSK" name="posk">
            <Select
              options={poskList
                ?.filter(
                  // 过滤掉 id 为 201 的 posk，本 posk 为内容展示值
                  (poskItem: CoursePoSKItemType) => poskItem.id !== "201"
                )
                .map((poskItem: CoursePoSKItemType) => ({
                  label: poskItem.title,
                  value: poskItem.id,
                }))}
            />
          </Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form>
      </Modal>

      {/* Course Pass New or Edit Modal */}
      <Modal open={isCPshown} onCancel={onCpCancel} footer={null}>
        <Form form={cpForm} onFinish={onCpOk}>
          <Form.Item label="Course Pass Title" name="title">
            <Input placeholder="please input course pass' title" />
          </Form.Item>
          <Form.Item label="Course Pass Description" name="description">
            <Input.TextArea placeholder="please input course pass' description" />
          </Form.Item>
          <Form.Item label="Course Pass Image URL" name="passImg">
            <Input placeholder="please input course pass' cover image url" />
          </Form.Item>
          <Button htmlType="submit">Submit</Button>
        </Form>
      </Modal>

      {/* Course PoSK New or Edit Modal */}
      <Modal open={isPoskShown} onCancel={onPoskCancel} footer={null}>
        <Form form={poskForm} onFinish={onPoskOk}>
          <Form.Item label="PoSK Title" name="title">
            <Input
              placeholder="please input PoSK' title"
              disabled={disablePoskEdit}
            />
          </Form.Item>
          <Form.Item label="PoSK Description" name="description">
            <Input.TextArea
              placeholder="please input PoSK' description"
              disabled={disablePoskEdit}
            />
          </Form.Item>
          <Form.Item label="PoSK Image URL" name="poskImg">
            <Input
              placeholder="please input PoSK' cover image url"
              disabled={disablePoskEdit}
            />
          </Form.Item>
          <Button htmlType="submit" hidden={disablePoskEdit}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};

export default Courses;
