import { Button, Input, Dropdown, Modal, Space, MenuProps } from "antd";
import { HtmlHTMLAttributes, useContext, useState } from "react";
import { DownOutlined } from "@ant-design/icons";
import { UserContext } from "../context/user";
import DeschoolAuth from "../components/DeSchoolAuth";
import { useDeSchool } from "../context/deschool";
import { useNavigate } from "react-router-dom";

export default function Header(props: HtmlHTMLAttributes<HTMLDivElement>) {
  const user = useContext(UserContext);
  const deschool = useDeSchool();
  const navigate = useNavigate();

  const [showLogin, setShowLogin] = useState(false);
  const [loginErr, setLoginErr] = useState<string | undefined>();

  const [loingInput, setLoingInput] = useState<{
    username?: string;
    password?: string;
  }>({});

  const handleLogin = async () => {
    const result = await user.login(loingInput.username!, loingInput.password!);
    if (result.success) {
      setShowLogin(false);
    } else {
      setLoginErr("username or password is wrong");
    }
  };

  const handleLogout = async () => {
    return user.logout();
  };

  const handleAuthenticate = async () => {
    deschool.showAuth(true);
  };

  const shortName = (name: string) => {
    if (name.length <= 6) {
      return name;
    }
    return name.slice(0, 3) + "..." + name.slice(-3);
  };

  const userMenuItems: MenuProps["items"] = [
    {
      label: "Profile",
      key: "Profile",
      onClick: () => {
        navigate("/profile");
      },
    },
    {
      label: (
        <>
          {deschool.user ? (
            <span title={deschool.user.userName}>
              {"DeSchool: " + shortName(deschool.user.userName)}
            </span>
          ) : (
            "Connect to DeSchool"
          )}
        </>
      ),
      key: "ConnectDeSchool",
      onClick: handleAuthenticate,
      disabled: deschool.user !== undefined,
    },
    {
      label: "Logout",
      key: "Logout",
      onClick: handleLogout,
    },
  ];

  return (
    <div
      {...props}
      className={`flex flex-row items-center justify-between ${props.className}`}
    >
      <h1
        className="flex-1 hover:text-purple-400 cursor-pointer"
        onClick={() => navigate("/")}
      >
        DeSchoolDemo
      </h1>
      <Modal
        title="Login"
        open={showLogin}
        centered
        onCancel={() => setShowLogin(false)}
        onOk={handleLogin}
        okText="Login"
      >
        <div className="mt-5 flex flex-col gap-5">
          <div>
            <span className="mr-2">username:</span>
            <Input
              placeholder="Username"
              id="userNameInput"
              onChange={(e) => {
                setLoingInput({ ...loingInput, username: e.target.value });
              }}
            />
          </div>
          <div>
            <span className="mr-2">password:</span>
            <Input.Password
              placeholder="Password"
              id="passwordInput"
              onChange={(e) => {
                setLoingInput({ ...loingInput, password: e.target.value });
              }}
            />
            {loginErr && <span className="text-red-500 my-2">{loginErr}</span>}
          </div>
        </div>
      </Modal>
      {!user.hasLogin && (
        <Button
          onClick={() => {
            setShowLogin(!showLogin);
          }}
        >
          Login
        </Button>
      )}
      {user.hasLogin && (
        <>
          <Dropdown menu={{ items: userMenuItems }}>
            <a href="#!" onClick={(e) => e.preventDefault()}>
              <Space>
                {user.username!}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          <Modal
            centered
            title="DeSchool Auth"
            open={!!deschool.authVisible}
            onCancel={() => deschool.showAuth(false)}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
          >
            <DeschoolAuth />
          </Modal>
        </>
      )}
    </div>
  );
}
