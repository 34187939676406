"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Auth = void 0;
const auth_generated_1 = require("./gql/auth.generated");
const types_generated_1 = require("../gql/types.generated");
class Auth {
    sdk;
    constructor(client) {
        this.sdk = (0, auth_generated_1.getSdk)(client);
    }
    async getLoginSignMessage(address, wallet) {
        const result = await this.sdk.getLoginSignMessage({
            address,
            wallet,
            signType: types_generated_1.SignType.Login
        });
        return result.data.result;
    }
    async verify(address, signature, walletType) {
        const result = await this.sdk.authVerify({
            address,
            signature,
            walletType
        });
        return result.data.result;
    }
    async authorizeApp(bizUserId) {
        const result = await this.sdk.authorizeApp({
            bizUserId,
        });
        return result.data.result;
    }
    async getBizUserInfo(bizUserId) {
        const result = await this.sdk.GetBizUserInfo({
            bizUserId,
        });
        return result.data.result;
    }
}
exports.Auth = Auth;
