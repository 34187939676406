"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSdk = exports.PassBindingFragmentDoc = exports.BindingItemFragmentDoc = exports.PassPagedResultFragmentDoc = exports.PassFragmentDoc = exports.PoskBindingFragmentDoc = exports.PoskPagedResultFragmentDoc = exports.PoskFragmentDoc = exports.UserFragmentDoc = void 0;
const graphql_tag_1 = require("graphql-tag");
exports.UserFragmentDoc = (0, graphql_tag_1.default) `
    fragment User on User {
  id
  userName
  address
  bio
  avatar
}
    `;
exports.PoskFragmentDoc = (0, graphql_tag_1.default) `
    fragment Posk on Posk {
  id
  name
  description
  image
  contractId
  createdAt
}
    `;
exports.PoskPagedResultFragmentDoc = (0, graphql_tag_1.default) `
    fragment PoskPagedResult on PoskPagedResult {
  total
  data {
    ...Posk
  }
  hasMore
}
    ${exports.PoskFragmentDoc}`;
exports.PoskBindingFragmentDoc = (0, graphql_tag_1.default) `
    fragment PoskBinding on PoskBinding {
  poskId
  targetId
  targetType
  createdAt
}
    `;
exports.PassFragmentDoc = (0, graphql_tag_1.default) `
    fragment Pass on Pass {
  id
  name
  description
  image
  website
  contractId
  price
  createdAt
}
    `;
exports.PassPagedResultFragmentDoc = (0, graphql_tag_1.default) `
    fragment PassPagedResult on PassPagedResult {
  total
  data {
    ...Pass
  }
  hasMore
}
    ${exports.PassFragmentDoc}`;
exports.BindingItemFragmentDoc = (0, graphql_tag_1.default) `
    fragment BindingItem on BindingItem {
  passId
  condition
}
    `;
exports.PassBindingFragmentDoc = (0, graphql_tag_1.default) `
    fragment PassBinding on PassBinding {
  targetId
  targetType
  bindings {
    ...BindingItem
  }
  createdAt
}
    ${exports.BindingItemFragmentDoc}`;
const defaultWrapper = (action, _operationName, _operationType) => action();
function getSdk(client, withWrapper = defaultWrapper) {
    return {};
}
exports.getSdk = getSdk;
