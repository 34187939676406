"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WalletType = exports.SignType = exports.ContractType = void 0;
/** smart contract type */
var ContractType;
(function (ContractType) {
    ContractType["Erc721"] = "ERC721";
    ContractType["Erc1155"] = "ERC1155";
})(ContractType || (exports.ContractType = ContractType = {}));
var SignType;
(function (SignType) {
    SignType["Login"] = "LOGIN";
})(SignType || (exports.SignType = SignType = {}));
var WalletType;
(function (WalletType) {
    WalletType["MetaMask"] = "MetaMask";
    WalletType["UniPass"] = "UniPass";
})(WalletType || (exports.WalletType = WalletType = {}));
