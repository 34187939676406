"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSdk = exports.UnbindTargetFromPassDocument = exports.BindTargetToPassDocument = exports.UpdatePassDocument = exports.CreatePassDocument = exports.GetPassBoundTargetsDocument = exports.GetUserPassesDocument = exports.GetPassDocument = exports.GetStudioPassesDocument = void 0;
const graphql_1 = require("graphql");
const graphql_tag_1 = require("graphql-tag");
const fragments_generated_1 = require("../../gql/fragments.generated");
exports.GetStudioPassesDocument = (0, graphql_tag_1.default) `
    query GetStudioPasses($studioId: String!) {
  result: getStudioPasses(studioId: $studioId) {
    ...Pass
  }
}
    ${fragments_generated_1.PassFragmentDoc}`;
exports.GetPassDocument = (0, graphql_tag_1.default) `
    query GetPass($id: String!) {
  result: getPass(id: $id) {
    ...Pass
  }
}
    ${fragments_generated_1.PassFragmentDoc}`;
exports.GetUserPassesDocument = (0, graphql_tag_1.default) `
    query GetUserPasses($bizUserId: String!, $page: PageAndSort) {
  result: getUserPasses(bizUserId: $bizUserId, page: $page) {
    ...PassPagedResult
  }
}
    ${fragments_generated_1.PassPagedResultFragmentDoc}`;
exports.GetPassBoundTargetsDocument = (0, graphql_tag_1.default) `
    query GetPassBoundTargets($id: String!) {
  result: getPassBoundTargets(id: $id) {
    ...PassBinding
  }
}
    ${fragments_generated_1.PassBindingFragmentDoc}`;
exports.CreatePassDocument = (0, graphql_tag_1.default) `
    mutation CreatePass($input: PassCreateRequest!) {
  result: createPass(input: $input) {
    ...Pass
  }
}
    ${fragments_generated_1.PassFragmentDoc}`;
exports.UpdatePassDocument = (0, graphql_tag_1.default) `
    mutation UpdatePass($id: String!, $input: PassUpdateRequest!) {
  result: updatePass(id: $id, input: $input) {
    ...Pass
  }
}
    ${fragments_generated_1.PassFragmentDoc}`;
exports.BindTargetToPassDocument = (0, graphql_tag_1.default) `
    mutation BindTargetToPass($input: PassBindingInput!) {
  result: bindTargetToPass(input: $input) {
    ...PassBinding
  }
}
    ${fragments_generated_1.PassBindingFragmentDoc}`;
exports.UnbindTargetFromPassDocument = (0, graphql_tag_1.default) `
    mutation UnbindTargetFromPass($targetId: String!) {
  result: unbindTargetFromPass(targetId: $targetId)
}
    `;
const defaultWrapper = (action, _operationName, _operationType) => action();
const GetStudioPassesDocumentString = (0, graphql_1.print)(exports.GetStudioPassesDocument);
const GetPassDocumentString = (0, graphql_1.print)(exports.GetPassDocument);
const GetUserPassesDocumentString = (0, graphql_1.print)(exports.GetUserPassesDocument);
const GetPassBoundTargetsDocumentString = (0, graphql_1.print)(exports.GetPassBoundTargetsDocument);
const CreatePassDocumentString = (0, graphql_1.print)(exports.CreatePassDocument);
const UpdatePassDocumentString = (0, graphql_1.print)(exports.UpdatePassDocument);
const BindTargetToPassDocumentString = (0, graphql_1.print)(exports.BindTargetToPassDocument);
const UnbindTargetFromPassDocumentString = (0, graphql_1.print)(exports.UnbindTargetFromPassDocument);
function getSdk(client, withWrapper = defaultWrapper) {
    return {
        GetStudioPasses(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetStudioPassesDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetStudioPasses', 'query');
        },
        GetPass(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetPassDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetPass', 'query');
        },
        GetUserPasses(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetUserPassesDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetUserPasses', 'query');
        },
        GetPassBoundTargets(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetPassBoundTargetsDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetPassBoundTargets', 'query');
        },
        CreatePass(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(CreatePassDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'CreatePass', 'mutation');
        },
        UpdatePass(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(UpdatePassDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'UpdatePass', 'mutation');
        },
        BindTargetToPass(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(BindTargetToPassDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'BindTargetToPass', 'mutation');
        },
        UnbindTargetFromPass(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(UnbindTargetFromPassDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'UnbindTargetFromPass', 'mutation');
        }
    };
}
exports.getSdk = getSdk;
