import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/user";
import { useDeSchool } from "../../context/deschool";
import { Button, Card } from "antd";
import { PassFragment, PoskFragment } from "@deschool-protocol/client";
import { sdk } from "../../api";
import Meta from "antd/es/card/Meta";

export function UserProfile() {
  const user = useContext(UserContext);
  const deschool = useDeSchool();

  const [passes, setPasses] = useState<PassFragment[]>([]);
  const [posks, setPosks] = useState<PoskFragment[]>([]);

  useEffect(() => {
    initData();
  });

  const initData = async () => {
    if (!user.id) return;
    const result = await sdk.profile.getProfile(user.id);
    if (result) {
      setPasses(result.passes || []);
      setPosks(result.posks || []);
    } else {
      console.log("no profile");
    }
  };
  return (
    <div>
      <h1 className="mt-10  mb-2 font-bold">App User Info</h1>
      <div>ID: {user.id} </div>
      <div>UserName: {user.username}</div>

      {deschool?.user && (
        <>
          <h1 className="mt-10 mb-2 font-bold">DeSchool User Info</h1>
          {deschool.user.avatar && (
            <img src={deschool.user.avatar} alt={deschool.user.userName} />
          )}
          <div>ID: {deschool.user.id} </div>
          <div>UserName: {deschool.user.userName} </div>
          <div>Wallet Address: {deschool.user.address} </div>
        </>
      )}
      {!deschool.user && <Button type="primary">Connect DeSchool</Button>}

      <h1 className="mt-10 mb-2 font-bold">Owned Passes</h1>
      {passes.map((pass) => (
        <Card
          hoverable
          style={{ width: 240 }}
          cover={<img alt="example" src={pass.image!} />}
        >
          <Meta title={pass.name} description={pass.description} />
        </Card>
      ))}
      <h1 className="mt-10 mb-2 font-bold">Owned Posks</h1>
      {posks.map((posk) => (
        <Card
          hoverable
          style={{ width: 240 }}
          cover={<img alt="example" src={posk.image!} />}
        >
          <Meta title={posk.name} description={posk.description} />
        </Card>
      ))}
    </div>
  );
}
