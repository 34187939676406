"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWallet = exports.WalletType = void 0;
const viem_1 = require("viem");
var WalletType;
(function (WalletType) {
    WalletType["MetaMask"] = "MetaMask";
    WalletType["UniPass"] = "UniPass";
})(WalletType || (exports.WalletType = WalletType = {}));
function createWallet(walletType, chain) {
    const clientConfig = {
        name: 'DeSchool',
        chain: chain,
        transport: null,
    };
    if (walletType == WalletType.MetaMask) {
        clientConfig.transport = createMetaMaskTranport();
    }
    else if (walletType == WalletType.UniPass) {
        clientConfig.transport = createUniPassTranport();
    }
    else {
        throw new Error('Unsupported wallet type');
    }
    return (0, viem_1.createWalletClient)(clientConfig);
}
exports.createWallet = createWallet;
function createMetaMaskTranport() {
    if (!window.ethereum) {
        throw new Error('MetaMask is not installed');
    }
    return (0, viem_1.custom)(window.ethereum);
}
function createUniPassTranport() {
    throw new Error('Unsupported wallet type');
}
