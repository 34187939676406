"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSdk = exports.GetBizUserInfoBatchDocument = exports.GetBizUserInfoDocument = exports.AuthorizeAppDocument = exports.AuthVerifyDocument = exports.GetLoginSignMessageDocument = void 0;
const graphql_1 = require("graphql");
const graphql_tag_1 = require("graphql-tag");
const fragments_generated_1 = require("../../gql/fragments.generated");
exports.GetLoginSignMessageDocument = (0, graphql_tag_1.default) `
    query getLoginSignMessage($address: String!, $wallet: WalletType!, $signType: SignType!) {
  result: getSignMessage(address: $address, wallet: $wallet, signType: $signType)
}
    `;
exports.AuthVerifyDocument = (0, graphql_tag_1.default) `
    mutation authVerify($address: String!, $signature: String!, $walletType: WalletType!) {
  result: userLogin(
    input: {address: $address, signature: $signature, walletType: $walletType}
  ) {
    jwtToken
    userId
    userName
    avatar
    address
  }
}
    `;
exports.AuthorizeAppDocument = (0, graphql_tag_1.default) `
    mutation authorizeApp($bizUserId: String!) {
  result: bindUser(input: {bizUserId: $bizUserId}) {
    userId
    bizUserId
  }
}
    `;
exports.GetBizUserInfoDocument = (0, graphql_tag_1.default) `
    query GetBizUserInfo($bizUserId: String!) {
  result: getBizUserInfo(bizUserId: $bizUserId) {
    ...User
  }
}
    ${fragments_generated_1.UserFragmentDoc}`;
exports.GetBizUserInfoBatchDocument = (0, graphql_tag_1.default) `
    query GetBizUserInfoBatch($bizUserIds: [String!]!) {
  result: getBizUserInfoBatch(bizUserIds: $bizUserIds) {
    bizUserId
    user {
      ...User
    }
  }
}
    ${fragments_generated_1.UserFragmentDoc}`;
const defaultWrapper = (action, _operationName, _operationType) => action();
const GetLoginSignMessageDocumentString = (0, graphql_1.print)(exports.GetLoginSignMessageDocument);
const AuthVerifyDocumentString = (0, graphql_1.print)(exports.AuthVerifyDocument);
const AuthorizeAppDocumentString = (0, graphql_1.print)(exports.AuthorizeAppDocument);
const GetBizUserInfoDocumentString = (0, graphql_1.print)(exports.GetBizUserInfoDocument);
const GetBizUserInfoBatchDocumentString = (0, graphql_1.print)(exports.GetBizUserInfoBatchDocument);
function getSdk(client, withWrapper = defaultWrapper) {
    return {
        getLoginSignMessage(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetLoginSignMessageDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'getLoginSignMessage', 'query');
        },
        authVerify(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(AuthVerifyDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'authVerify', 'mutation');
        },
        authorizeApp(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(AuthorizeAppDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'authorizeApp', 'mutation');
        },
        GetBizUserInfo(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetBizUserInfoDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetBizUserInfo', 'query');
        },
        GetBizUserInfoBatch(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetBizUserInfoBatchDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetBizUserInfoBatch', 'query');
        }
    };
}
exports.getSdk = getSdk;
