import { WalletType } from "@deschool-protocol/web";
import { Button, message } from "antd";
import { useContext, useState } from "react";
import { UserContext } from "../context/user";
import { polygon } from "viem/chains";
import { sdk } from "../api/deschool";

export default function DeschoolAuth(props: {
  onsuccess?: (result: { userId: string; bizUserId: string }) => void;
}) {
  const user = useContext(UserContext);

  const [wallteType, setWallteType] = useState<WalletType | undefined>();
  const [isLogining, setIsLogining] = useState(false);

  const handleAuth = async (wallteType: WalletType) => {
    setWallteType(wallteType);
    setIsLogining(true);
    try {
      const result = await sdk.authorize(user.id!, wallteType, polygon);
      console.log(result);
      props.onsuccess?.(result);
    } catch (e: any) {
      message.error(e.message);
    } finally {
      setIsLogining(false);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <Button
        disabled={isLogining}
        loading={wallteType === WalletType.MetaMask && isLogining}
        onClick={() => handleAuth(WalletType.MetaMask)}
      >
        MetaMask
      </Button>
      <Button
        disabled={isLogining}
        loading={wallteType === WalletType.UniPass && isLogining}
        onClick={() => handleAuth(WalletType.UniPass)}
      >
        Email
      </Button>
    </div>
  );
}
