import { createBrowserRouter } from "react-router-dom";
import { UserProfile } from "../pages/profile";
import App from "../App";
import Courses from "../pages/courses";

const router = createBrowserRouter([
  {
    path: "/",
    Component: App,
    children: [
      {
        path: "profile",
        Component: UserProfile,
      },
      {
        path: "/",
        Component: () => Courses(),
      },
    ],
  },
]);
export default router;
