"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Posk = void 0;
const posk_generated_1 = require("./gql/posk.generated");
class Posk {
    sdk;
    constructor(client) {
        this.sdk = (0, posk_generated_1.getSdk)(client);
    }
    async getPosk(id) {
        const result = await this.sdk.GetPosk({
            id,
        });
        return result.data.result;
    }
    async getUserPosks(bizUserId, page) {
        const result = await this.sdk.GetUserPosks({
            bizUserId,
            page,
        });
        return result.data.result;
    }
    async getStudioPosks(studioId) {
        const result = await this.sdk.GetStudioPosks({
            studioId,
        });
        return result.data.result;
    }
    async getPoskBoundTargets(id) {
        const result = await this.sdk.GetPoskBoundTargets({
            id,
        });
        return result.data.result;
    }
    async createPosk(input) {
        const result = await this.sdk.CreatePosk({
            input,
        });
        return result.data.result;
    }
    async updatePosk(id, input) {
        const result = await this.sdk.UpdatePosk({
            id,
            input,
        });
        return result.data.result;
    }
    async bindPoskTarget(input) {
        const result = await this.sdk.BindTargetToPosk({
            input,
        });
        return result.data.result;
    }
    async airdropPosk(targetId, bizUserId) {
        const result = await this.sdk.AirdropPosk({
            targetId,
            bizUserId,
        });
        return result.data.result;
    }
}
exports.Posk = Posk;
