"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pass = void 0;
const pass_generated_1 = require("./gql/pass.generated");
class Pass {
    sdk;
    constructor(client) {
        this.sdk = (0, pass_generated_1.getSdk)(client);
    }
    async getPass(id) {
        const result = await this.sdk.GetPass({
            id,
        });
        return result.data.result;
    }
    async getUserPasses(bizUserId, page) {
        const result = await this.sdk.GetUserPasses({
            bizUserId,
            page,
        });
        return result.data.result;
    }
    async getStudioPasses(studioId) {
        const result = await this.sdk.GetStudioPasses({
            studioId,
        });
        return result.data.result;
    }
    async getPassBoundTargets(id) {
        const result = await this.sdk.GetPassBoundTargets({
            id,
        });
        return result.data.result;
    }
    async createPass(input) {
        const result = await this.sdk.CreatePass({
            input,
        });
        return result.data.result;
    }
    async updatePass(id, input) {
        const result = await this.sdk.UpdatePass({
            id,
            input,
        });
        return result.data.result;
    }
    async bindTargetToPass(input) {
        const result = await this.sdk.BindTargetToPass({
            input,
        });
        return result.data.result;
    }
    async unbindTargetFromPass(targetId) {
        const result = await this.sdk.UnbindTargetFromPass({
            targetId,
        });
        return result.data.result;
    }
}
exports.Pass = Pass;
