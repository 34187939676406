"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSdk = exports.AirdropPoskDocument = exports.UnbindTargetFromPoskDocument = exports.BindTargetToPoskDocument = exports.UpdatePoskDocument = exports.CreatePoskDocument = exports.GetPoskBoundTargetsDocument = exports.GetStudioPosksDocument = exports.GetUserPosksDocument = exports.GetPoskDocument = void 0;
const graphql_1 = require("graphql");
const graphql_tag_1 = require("graphql-tag");
const fragments_generated_1 = require("../../gql/fragments.generated");
exports.GetPoskDocument = (0, graphql_tag_1.default) `
    query GetPosk($id: String!) {
  result: getPosk(id: $id) {
    ...Posk
  }
}
    ${fragments_generated_1.PoskFragmentDoc}`;
exports.GetUserPosksDocument = (0, graphql_tag_1.default) `
    query GetUserPosks($bizUserId: String!, $page: PageAndSort) {
  result: getUserPosks(bizUserId: $bizUserId, page: $page) {
    ...PoskPagedResult
  }
}
    ${fragments_generated_1.PoskPagedResultFragmentDoc}`;
exports.GetStudioPosksDocument = (0, graphql_tag_1.default) `
    query GetStudioPosks($studioId: String!) {
  result: getStudioPosks(studioId: $studioId) {
    ...Posk
  }
}
    ${fragments_generated_1.PoskFragmentDoc}`;
exports.GetPoskBoundTargetsDocument = (0, graphql_tag_1.default) `
    query GetPoskBoundTargets($id: String!) {
  result: getPoskBoundTargets(id: $id) {
    ...PoskBinding
  }
}
    ${fragments_generated_1.PoskBindingFragmentDoc}`;
exports.CreatePoskDocument = (0, graphql_tag_1.default) `
    mutation CreatePosk($input: PoskCreateRequest!) {
  result: createPosk(input: $input) {
    ...Posk
  }
}
    ${fragments_generated_1.PoskFragmentDoc}`;
exports.UpdatePoskDocument = (0, graphql_tag_1.default) `
    mutation UpdatePosk($id: String!, $input: PoskUpdateRequest!) {
  result: updatePosk(id: $id, input: $input) {
    ...Posk
  }
}
    ${fragments_generated_1.PoskFragmentDoc}`;
exports.BindTargetToPoskDocument = (0, graphql_tag_1.default) `
    mutation BindTargetToPosk($input: PoskBindingInput!) {
  result: bindTargetToPosk(input: $input) {
    ...PoskBinding
  }
}
    ${fragments_generated_1.PoskBindingFragmentDoc}`;
exports.UnbindTargetFromPoskDocument = (0, graphql_tag_1.default) `
    mutation unbindTargetFromPosk($poskId: String!, $targetId: String!) {
  result: unbindTargetFromPosk(poskId: $poskId, targetId: $targetId)
}
    `;
exports.AirdropPoskDocument = (0, graphql_tag_1.default) `
    mutation AirdropPosk($targetId: String!, $bizUserId: String!) {
  result: airdropPosk(targetId: $targetId, bizUserId: $bizUserId)
}
    `;
const defaultWrapper = (action, _operationName, _operationType) => action();
const GetPoskDocumentString = (0, graphql_1.print)(exports.GetPoskDocument);
const GetUserPosksDocumentString = (0, graphql_1.print)(exports.GetUserPosksDocument);
const GetStudioPosksDocumentString = (0, graphql_1.print)(exports.GetStudioPosksDocument);
const GetPoskBoundTargetsDocumentString = (0, graphql_1.print)(exports.GetPoskBoundTargetsDocument);
const CreatePoskDocumentString = (0, graphql_1.print)(exports.CreatePoskDocument);
const UpdatePoskDocumentString = (0, graphql_1.print)(exports.UpdatePoskDocument);
const BindTargetToPoskDocumentString = (0, graphql_1.print)(exports.BindTargetToPoskDocument);
const UnbindTargetFromPoskDocumentString = (0, graphql_1.print)(exports.UnbindTargetFromPoskDocument);
const AirdropPoskDocumentString = (0, graphql_1.print)(exports.AirdropPoskDocument);
function getSdk(client, withWrapper = defaultWrapper) {
    return {
        GetPosk(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetPoskDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetPosk', 'query');
        },
        GetUserPosks(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetUserPosksDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetUserPosks', 'query');
        },
        GetStudioPosks(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetStudioPosksDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetStudioPosks', 'query');
        },
        GetPoskBoundTargets(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(GetPoskBoundTargetsDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'GetPoskBoundTargets', 'query');
        },
        CreatePosk(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(CreatePoskDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'CreatePosk', 'mutation');
        },
        UpdatePosk(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(UpdatePoskDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'UpdatePosk', 'mutation');
        },
        BindTargetToPosk(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(BindTargetToPoskDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'BindTargetToPosk', 'mutation');
        },
        unbindTargetFromPosk(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(UnbindTargetFromPoskDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'unbindTargetFromPosk', 'mutation');
        },
        AirdropPosk(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(AirdropPoskDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'AirdropPosk', 'mutation');
        }
    };
}
exports.getSdk = getSdk;
