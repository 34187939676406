import "./App.css";
import Header from "./layout/header";
import { Outlet } from "react-router-dom";
function App() {
  return (
    <div className="flex flex-col max-w-[1200px] m-auto">
      <Header className=" bg-gray-50 px-4" />
      <div>
        <Outlet />
        {/* <RouterProvider router={router}></RouterProvider> */}
      </div>
    </div>
  );
}

export default App;
