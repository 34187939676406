"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createGraphQLClient = void 0;
const graphql_request_1 = require("graphql-request");
function createGraphQLClient(config) {
    return new graphql_request_1.GraphQLClient(config.endpoint, {
        requestMiddleware: (requestConfig) => {
            const headers = {};
            if (config.apikey) {
                headers["x-api-key"] = config.apikey;
            }
            if (config.accessToken) {
                headers["Authorization"] = `Bearer ${config.accessToken}`;
            }
            requestConfig.headers = {
                ...requestConfig.headers,
                ...headers,
            };
            return requestConfig;
        },
    });
}
exports.createGraphQLClient = createGraphQLClient;
