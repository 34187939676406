"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeSchoolClient = void 0;
const auth_1 = require("./auth");
const gqlclient_1 = require("./request/gqlclient");
const pass_1 = require("./pass");
const posk_1 = require("./posk");
const profile_1 = require("./profile");
class DeSchoolClient {
    config;
    _client;
    constructor(config) {
        this.config = config;
        this._client = (0, gqlclient_1.createGraphQLClient)(config);
    }
    get auth() {
        return new auth_1.Auth(this._client);
    }
    get pass() {
        return new pass_1.Pass(this._client);
    }
    get posk() {
        return new posk_1.Posk(this._client);
    }
    get profile() {
        return new profile_1.Profile(this._client);
    }
    setAccessToken(token) {
        this.config.accessToken = token;
    }
}
exports.DeSchoolClient = DeSchoolClient;
