"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSdk = exports.ProfileBatchDocument = exports.ProfileByWalletDocument = exports.ProfileDocument = void 0;
const graphql_1 = require("graphql");
const graphql_tag_1 = require("graphql-tag");
const fragments_generated_1 = require("../../gql/fragments.generated");
exports.ProfileDocument = (0, graphql_tag_1.default) `
    query Profile($bizUserId: String!) {
  result: profile(bizUserId: $bizUserId) {
    bizUserId
    userId
    user {
      ...User
    }
    passes {
      ...Pass
    }
    posks {
      ...Posk
    }
  }
}
    ${fragments_generated_1.UserFragmentDoc}
${fragments_generated_1.PassFragmentDoc}
${fragments_generated_1.PoskFragmentDoc}`;
exports.ProfileByWalletDocument = (0, graphql_tag_1.default) `
    query profileByWallet($wallet: String!) {
  result: profileByWallet(wallet: $wallet) {
    bizUserId
    userId
    user {
      ...User
    }
    passes {
      ...Pass
    }
    posks {
      ...Posk
    }
  }
}
    ${fragments_generated_1.UserFragmentDoc}
${fragments_generated_1.PassFragmentDoc}
${fragments_generated_1.PoskFragmentDoc}`;
exports.ProfileBatchDocument = (0, graphql_tag_1.default) `
    query profileBatch($bizUserIds: [String!]!) {
  result: profileBatch(bizUserIds: $bizUserIds) {
    bizUserId
    userId
    user {
      ...User
    }
    passes {
      ...Pass
    }
    posks {
      ...Posk
    }
  }
}
    ${fragments_generated_1.UserFragmentDoc}
${fragments_generated_1.PassFragmentDoc}
${fragments_generated_1.PoskFragmentDoc}`;
const defaultWrapper = (action, _operationName, _operationType) => action();
const ProfileDocumentString = (0, graphql_1.print)(exports.ProfileDocument);
const ProfileByWalletDocumentString = (0, graphql_1.print)(exports.ProfileByWalletDocument);
const ProfileBatchDocumentString = (0, graphql_1.print)(exports.ProfileBatchDocument);
function getSdk(client, withWrapper = defaultWrapper) {
    return {
        Profile(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(ProfileDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'Profile', 'query');
        },
        profileByWallet(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(ProfileByWalletDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'profileByWallet', 'query');
        },
        profileBatch(variables, requestHeaders) {
            return withWrapper((wrappedRequestHeaders) => client.rawRequest(ProfileBatchDocumentString, variables, { ...requestHeaders, ...wrappedRequestHeaders }), 'profileBatch', 'query');
        }
    };
}
exports.getSdk = getSdk;
