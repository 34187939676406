import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/reset.css";
import "./index.css";
import { ConfigProvider, theme } from "antd";
import { UserProvider } from "./context/user";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { DeSchoolProvider } from "./context/deschool";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          // colorPrimary: "#00b96b",
        },
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <UserProvider>
        <DeSchoolProvider>
          <RouterProvider router={router}></RouterProvider>
        </DeSchoolProvider>
      </UserProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
