import { v4 as uuidv4 } from 'uuid';

export interface UserInfo {
  id: string;
  username: string;
}

export interface LoginResult {
  success: boolean;
  user?: UserInfo;
}

export class UserApi {
  login(username: string, password: string): Promise<LoginResult> {
    if (username === "admin" && password === "admin") {
      let userId = localStorage.getItem("userId")
      if (!userId) {
        userId = uuidv4()
        localStorage.setItem("userId", userId)
      }
      return Promise.resolve({
        success: true,
        user: {
          id: userId,
          username: "admin"
        }
      });
    }
    return Promise.resolve({
      success: false
    });
  }
}

export const userApi = new UserApi();