import { createContext, useContext, useEffect } from "react";
import { UserContext } from "./user";
import { sdk } from "../api/deschool";
import React from "react";

export type DeschoolUserInfo = {
  id: string;
  userName: string;
  address: string;
  bio: string;
  avatar?: string | null;
};

export interface DeSchoolInfo {
  user?: DeschoolUserInfo;
  authVisible?: boolean;
  showAuth: (visbile: boolean) => void;
}

export const DeSchoolContext = createContext<DeSchoolInfo>({
  showAuth: () => {},
});

export const DeSchoolProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const appUser = useContext(UserContext);
  const [user, setUser] = React.useState<DeschoolUserInfo>();
  const [authVisible, setAuthVisible] = React.useState(false);

  const checkDeschoolUser = async (bizUserId: string) => {
    const info = await sdk.auth.getBizUserInfo(bizUserId);
    if (info?.id) {
      setUser(info);
    } else {
      setUser(undefined);
    }
  };

  useEffect(() => {
    if (appUser.id) {
      checkDeschoolUser(appUser.id);
    } else {
      setUser(undefined);
    }
  }, [appUser]);

  const showAuth = () => {
    setAuthVisible(true);
  };
  return (
    <DeSchoolContext.Provider value={{ user, authVisible, showAuth }}>
      {children}
    </DeSchoolContext.Provider>
  );
};

export function useDeSchool() {
  return useContext(DeSchoolContext);
}
