"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Profile = void 0;
const profile_generated_1 = require("./gql/profile.generated");
class Profile {
    sdk;
    constructor(client) {
        this.sdk = (0, profile_generated_1.getSdk)(client);
    }
    async getProfile(bizUserId) {
        const result = await this.sdk.Profile({
            bizUserId
        });
        return result.data.result;
    }
    async getProfileByWallet(wallet) {
        const result = await this.sdk.profileByWallet({
            wallet
        });
        return result.data.result;
    }
    async getProfileBatch(bizUserIds) {
        const result = await this.sdk.profileBatch({
            bizUserIds
        });
        return result.data.result;
    }
}
exports.Profile = Profile;
